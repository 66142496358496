module.exports = new Promise(((remoteName) => (resolve, reject) => {
  const currentModule = window[remoteName]
  // Remote Script has loaded
  if (currentModule) {
    return resolve(window[remoteName])
  }

  const onLoad = () => {
    resolve(window[remoteName])
  }

  const onError = () => {
    reject(`Script for ${remoteName} failed to load.`)
  }

  const scriptId = `${remoteName}-remote`
  const currentScript = document.getElementById(scriptId)

  // Remote script has not yet loaded.
  if (currentScript) {
    currentScript.addEventListener('load', onLoad, { once: true })
    currentScript.addEventListener('error', onError, { once: true })
  } else {
    const errorMessage = `Unable to find remote ${scriptId}`
    console.error(errorMessage)
    reject(errorMessage)
  }
})('components'));